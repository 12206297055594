var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "declaration-detail-index" },
    [
      _vm.ifShowBasic
        ? _c(
            "div",
            { staticClass: "basic-detail-page" },
            [
              _c("div", { staticClass: "basic-detail-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.tdOccupationalHazardDeclarationExp.unitName) +
                    " "
                )
              ]),
              _c("hr", { staticClass: "hr-dashed" }),
              _c(
                "el-row",
                { staticClass: "top-bottom-pad20" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "basic-descriptions",
                          attrs: { column: 1 }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "社会信用代码" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .creditCode
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "经济类型" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .economicName
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "企业规模" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.enterpriseScaleDict[
                                    _vm.tdOccupationalHazardDeclarationExp
                                      .enterpriseScale
                                  ]
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "行业分类" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .industryName
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "basic-descriptions",
                          attrs: { column: 1 }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "注册地区" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .registerName
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "注册地址" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .registerAddr
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "经营地区" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp.bizName
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "经营地址" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .workplaceAddr
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "last-basic-descriptions",
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "el-descriptions",
                        { attrs: { column: 1 } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "法定代表人" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .legalPerson
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "法定代表人电话" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .legalPersonPhone
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "联系人" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .linkManager
                                )
                              )
                            ]
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "联系人电话" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tdOccupationalHazardDeclarationExp
                                    .linkManagerContact
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("base-headline", { attrs: { title: "人员情况" } }),
      _c("div", { staticClass: "basic-detail-page top-bottom-pad10" }, [
        _c("div", { staticClass: "flex-div" }, [
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("在册职工总数：")
            ]),
            _c("span", { staticClass: "font-size18-weight400 color-0037c7" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp.totalStaffNum,
                    "人"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "right-line-22" }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("外委人员总数：")
            ]),
            _c("span", { staticClass: "font-size18-weight400 color-0037c7" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp
                      .totalSubcontractingStaff,
                    "人"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "right-line-22" }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("接害总人数 (含外委)：")
            ]),
            _c("span", { staticClass: "font-size18-weight400 color-fa940f" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp.totalVictimsNum,
                    "人"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "right-line-22" }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("职业病累计人数：")
            ]),
            _c("span", { staticClass: "font-size18-weight400 color-ed0e25" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp.cumulativePatients,
                    "人"
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "right-line-22" }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("在册接害人数：")
            ]),
            _c("span", { staticClass: "font-size18-weight400 color-ed0e25" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp.workerNum,
                    "人"
                  )
                )
              )
            ])
          ])
        ])
      ]),
      _c("base-headline", { attrs: { title: "职业卫生培训情况" } }),
      _c("div", { staticClass: "basic-detail-page top-bottom-pad10" }, [
        _c("div", { staticClass: "flex-div" }, [
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("主要负责人培训：")
            ]),
            _c(
              "span",
              {
                class: _vm.tdOccupationalHazardDeclarationExp.principalTraining
                  ? "color-159487"
                  : "color-dd3838"
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.tdOccupationalHazardDeclarationExp.principalTraining
                      ? "已培训"
                      : "未培训"
                  )
                )
              ]
            )
          ]),
          _c("div", {
            staticClass: "right-line-22",
            staticStyle: { padding: "0 17px" }
          }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("职业卫生管理人员培训：")
            ]),
            _c(
              "span",
              {
                class: _vm.tdOccupationalHazardDeclarationExp.managerTraining
                  ? "color-159487"
                  : "color-dd3838"
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.tdOccupationalHazardDeclarationExp.managerTraining
                      ? "已培训"
                      : "未培训"
                  )
                )
              ]
            )
          ]),
          _c("div", {
            staticClass: "right-line-22",
            staticStyle: { padding: "0 17px" }
          }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("危害因素年度培训总人数：")
            ]),
            _c("span", { staticClass: "detail-value" }, [
              _vm._v(
                _vm._s(
                  _vm.formatNullDataByNoTable(
                    _vm.tdOccupationalHazardDeclarationExp.totalTrainee,
                    "人"
                  )
                )
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "flex-div" }, [
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("主要负责人培训证明：")
            ]),
            _vm.tdOccupationalHazardDeclarationExp.principalTraining &&
            _vm.$zwxBase.verifyIsNotBlank(
              _vm.tdOccupationalHazardDeclarationExp.principalTrainingPath
            )
              ? _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              [
                                {
                                  fileName: "主要负责人培训证明",
                                  filePath:
                                    _vm.tdOccupationalHazardDeclarationExp
                                      .principalTrainingPath
                                }
                              ],
                              0
                            )
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ],
                  1
                )
              : _c("span", [_vm._v("——")])
          ]),
          _c("div", { staticClass: "right-line-22" }),
          _c("div", [
            _c("span", { staticClass: "detail-label" }, [
              _vm._v("职业卫生管理人员培训证明：")
            ]),
            _vm.tdOccupationalHazardDeclarationExp.managerTraining &&
            _vm.$zwxBase.verifyIsNotBlank(
              _vm.tdOccupationalHazardDeclarationExp.managerTrainingPath
            )
              ? _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              _vm.tdOccupationalHazardDeclarationExp
                                .managerTrainingPath,
                              0
                            )
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "right-line-22" })
        ])
      ]),
      _c("base-headline", {
        staticClass: "bdBottom-no",
        attrs: { title: "主要产品" }
      }),
      _c(
        "div",
        { staticClass: "detail-card-0" },
        [
          _c(
            "el-card",
            { staticClass: "zwx-card card-text-item-height35 card-text-item" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      attrs: {
                        "row-key": "rid+'MajorProduct'",
                        data:
                          _vm.tdOccupationalHazardDeclarationExp
                            .tdMajorProductExpList,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "产品名称",
                          width: "400",
                          "header-align": "center",
                          align: "left"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dosage",
                          label: "年产量",
                          width: "200",
                          "show-overflow-tooltip": "",
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unit",
                          label: "计量单位",
                          width: "150",
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "",
                          "header-align": "left",
                          align: "left"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-headline",
        { staticClass: "bdTop-no", attrs: { title: "危害因素情况" } },
        [
          null ==
            _vm.tdOccupationalHazardDeclarationExp.tdHealthSupervisionExp ||
          !_vm.tdOccupationalHazardDeclarationExp.tdHealthSupervisionExp
            .existsSupervision
            ? _c("div", { staticClass: "no-health" }, [
                _c("span", { staticClass: "title" }, [_vm._v("未开展体检")])
              ])
            : _vm._e()
        ]
      ),
      _vm._l(
        _vm.tdOccupationalHazardDeclarationExp.declarationHazardMainPojoList,
        function(declarationHazardMainPojo, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "basic-detail-page top-bottom-pad10" }, [
              _c("div", { staticClass: "flex-div" }, [
                _c("div", { class: "hazards-sort-title color-" + index }, [
                  _vm._v(
                    _vm._s(
                      _vm.hazardsSortDict[declarationHazardMainPojo.hazardsSort]
                    )
                  )
                ]),
                _c("div", { staticClass: "width-160" }, [
                  _c("span", { staticClass: "detail-label" }, [
                    _vm._v("总接触人数：")
                  ]),
                  _c("span", { staticClass: "detail-value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          declarationHazardMainPojo.totalContacts,
                          "人"
                        )
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "right-line-22" }),
                _c("div", { staticClass: "width-160" }, [
                  _c("span", { staticClass: "detail-label" }, [
                    _vm._v("总体检人数：")
                  ]),
                  _c("span", { staticClass: "detail-value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          declarationHazardMainPojo.totalHealth,
                          "人"
                        )
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "right-line-22" }),
                _c("div", { staticClass: "width-160" }, [
                  _c("span", { staticClass: "detail-label" }, [
                    _vm._v("总检测点数：")
                  ]),
                  _c("span", { staticClass: "detail-value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          declarationHazardMainPojo.totalPoints,
                          ""
                        )
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "right-line-22" }),
                _c("div", { staticClass: "width-160" }, [
                  _c("span", { staticClass: "detail-label" }, [
                    _vm._v("总超标点数：")
                  ]),
                  _c("span", { staticClass: "detail-value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatNullDataByNoTable(
                          declarationHazardMainPojo.totalOverproofPoints,
                          ""
                        )
                      )
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "detail-card-0" },
              [
                _c(
                  "el-card",
                  {
                    staticClass:
                      "zwx-card card-text-item-height35 card-text-item"
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "zwx-table",
                            attrs: {
                              "row-key":
                                "hazardsSortCode+'declarationHazardSub'",
                              data:
                                declarationHazardMainPojo.declarationHazardSubPojoList,
                              border: "",
                              stripe: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "hazardsSortName",
                                label: "接触危害因素",
                                width: "400",
                                "header-align": "center",
                                align: "left"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "contactsNum",
                                label: "接触人数",
                                width: "200",
                                formatter: _vm.formatNullDataByTable,
                                "show-overflow-tooltip": "",
                                "header-align": "center",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "healthNum",
                                label: "体检人数",
                                width: "150",
                                formatter: _vm.formatNullDataByTable,
                                "header-align": "center",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "pointsNum",
                                label: "检测点数",
                                width: "150",
                                formatter: _vm.formatNullDataByTable,
                                "header-align": "center",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "overproofPointsNum",
                                label: "超标点数",
                                width: "150",
                                formatter: _vm.formatNullDataByTable,
                                "header-align": "center",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "",
                                "header-align": "left",
                                align: "left"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        }
      ),
      _c("base-headline", {
        staticClass: "bdBottom-no bdTop-no",
        attrs: { title: "职业健康监护记录" }
      }),
      _c(
        "div",
        { staticClass: "detail-card-0" },
        [
          _c(
            "el-card",
            { staticClass: "zwx-card card-text-item-height35 card-text-item" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      attrs: {
                        "row-key": "creditCode+'HealthSupervisionRecord'",
                        data:
                          _vm.tdOccupationalHazardDeclarationExp
                            .tdHealthSupervisionRecordExpList,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "unitName",
                          label: "检查机构名称",
                          width: "400",
                          "header-align": "center",
                          align: "left"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reportNo",
                          label: "检查报告编号",
                          width: "200",
                          formatter: _vm.formatNullDataByTable,
                          "show-overflow-tooltip": "",
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "creditCode",
                          label: "社会信用代码",
                          width: "300",
                          formatter: _vm.formatNullDataByTable,
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contactInfo",
                          label: "联系电话",
                          width: "150",
                          formatter: _vm.formatNullDataByTable,
                          "header-align": "center",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "",
                          "header-align": "left",
                          align: "left"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("base-headline", {
            staticClass: "bdBottom-no bdTop-no",
            attrs: { title: "场所检测评价记录" }
          }),
          _c(
            "div",
            { staticClass: "detail-card-0" },
            [
              _c(
                "el-card",
                {
                  staticClass: "zwx-card card-text-item-height35 card-text-item"
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "zwx-table",
                          attrs: {
                            "row-key": "creditCode+'HazardDetectionRecord'",
                            data:
                              _vm.tdOccupationalHazardDeclarationExp
                                .tdHazardDetectionRecordExpList,
                            border: "",
                            stripe: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "unitName",
                              label: "检测机构名称",
                              width: "400",
                              "header-align": "center",
                              align: "left"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "reportNo",
                              label: "检测报告编号",
                              width: "200",
                              formatter: _vm.formatNullDataByTable,
                              "show-overflow-tooltip": "",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ row }) {
                                  return [
                                    row.detectionReportUid
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "td-text",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.previewFile(
                                                  row.detectionReportUid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.reportNo) + " "
                                            )
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(row.reportNo))
                                        ])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "creditCode",
                              label: "社会信用代码",
                              width: "300",
                              formatter: _vm.formatNullDataByTable,
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "contactInfo",
                              label: "联系电话",
                              width: "150",
                              formatter: _vm.formatNullDataByTable,
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "",
                              "header-align": "left",
                              align: "left"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("base-headline", {
        staticClass: "bdTop-no",
        attrs: { title: "申报信息" }
      }),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          attrs: {
            "row-key": "creditCode+'HazardDetectionRecord'",
            data: _vm.list,
            "show-header": false,
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "five",
              label: "申报年份：",
              width: "120",
              "header-align": "center",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "six",
              label: "",
              width: "180",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "one",
              label: "申报日期：",
              width: "120",
              "header-align": "center",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "two",
              label: "",
              width: "180",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "three",
              label: "填报人：",
              width: "100",
              "header-align": "center",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "four",
              label: "",
              "header-align": "center",
              align: "left"
            }
          })
        ],
        1
      ),
      _vm.tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList &&
      _vm.tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList
        .length != 0
        ? _c("base-headline", {
            staticClass: "bdTop-no",
            attrs: { title: "申报附件" }
          })
        : _vm._e(),
      _vm.tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList &&
      _vm.tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList
        .length != 0
        ? _c(
            "div",
            { staticClass: "attachments" },
            _vm._l(
              _vm.tdOccupationalHazardDeclarationExp
                .tdHazardDeclarationAnnexExpList,
              function(annex, index) {
                return _c(
                  "span",
                  {
                    key: annex.rid + "Annex",
                    on: {
                      click: function($event) {
                        return _vm.openFilePreview(
                          _vm.tdOccupationalHazardDeclarationExp
                            .tdHazardDeclarationAnnexExpList,
                          index
                        )
                      }
                    }
                  },
                  [
                    _c("i", {
                      class: _vm.resolvePre(annex),
                      staticStyle: { color: "#316CF5!important" }
                    }),
                    _vm._v(" " + _vm._s(annex.fileName) + " ")
                  ]
                )
              }
            ),
            0
          )
        : _vm._e(),
      (_vm.tdOccupationalHazardDeclarationExp.declareStatus === 2 ||
        _vm.tdOccupationalHazardDeclarationExp.declareStatus === 3) &&
      _vm.$zwxBase.verifyIsNotBlank(_vm.ohdApprovalRecord.approveResult)
        ? _c(
            "div",
            [
              _c("base-headline", {
                class: {
                  "bdTop-no": !(
                    _vm.tdOccupationalHazardDeclarationExp
                      .tdHazardDeclarationAnnexExpList &&
                    _vm.tdOccupationalHazardDeclarationExp
                      .tdHazardDeclarationAnnexExpList.length != 0
                  )
                },
                attrs: { title: "审核信息" }
              }),
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  attrs: {
                    "row-key": "creditCode+'HazardDetectionRecord'",
                    data: _vm.infoList,
                    "span-method": _vm.arraySpanMethod,
                    "show-header": false,
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "one",
                      label: "审核状态",
                      width: "120",
                      "header-align": "center",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "two",
                      label: "审核状态",
                      width: "180",
                      formatter: _vm.formatNullDataByTable,
                      "header-align": "center",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function({ row }) {
                            return [
                              row.one == "审核状态："
                                ? _c("div", [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            _vm.ohdApprovalRecord
                                              .approveResult == 1
                                              ? "#00B652"
                                              : "red"
                                        }
                                      },
                                      [
                                        _vm.ohdApprovalRecord.approveResult == 1
                                          ? _c("i", {
                                              staticClass: "el-icon-success"
                                            })
                                          : _c("i", {
                                              staticClass: "el-icon-error"
                                            }),
                                        _vm._v(" " + _vm._s(row.two) + " ")
                                      ]
                                    )
                                  ])
                                : _c("div", [_vm._v(_vm._s(row.two))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1640368327
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "three",
                      label: "",
                      width: "100",
                      "header-align": "center",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "four",
                      label: "",
                      formatter: _vm.formatNullDataByTable,
                      "header-align": "center",
                      align: "left"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function({ row }) {
                            return [
                              row.three == "审核附件："
                                ? _c("div", [
                                    _vm.$zwxBase.verifyIsNotBlank(row.four)
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "zwx-button zwx-button-text-26",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openFilePreview(
                                                      [
                                                        {
                                                          fileName: "审核附件",
                                                          filePath: row.four
                                                        }
                                                      ],
                                                      0
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$zwxBase.verifyIsBlank(row.four)
                                      ? _c("span", [_vm._v("-")])
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              row.three == "回执单："
                                ? _c("div", [
                                    _vm.$zwxBase.verifyIsNotBlank(
                                      _vm.tdOccupationalHazardDeclarationExp
                                        .receiptPath
                                    )
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "zwx-button zwx-button-text-26",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.openFilePreview(
                                                      [
                                                        {
                                                          fileName:
                                                            _vm
                                                              .tdOccupationalHazardDeclarationExp
                                                              .unitName +
                                                            "-回执单(" +
                                                            _vm
                                                              .tdOccupationalHazardDeclarationExp
                                                              .receiptNo +
                                                            ")",
                                                          filePath:
                                                            _vm
                                                              .tdOccupationalHazardDeclarationExp
                                                              .receiptPath
                                                        }
                                                      ],
                                                      0
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("查看")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$zwxBase.verifyIsBlank(
                                      _vm.tdOccupationalHazardDeclarationExp
                                        .receiptPath
                                    )
                                      ? _c("span", [_vm._v("-")])
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              row.three != "审核附件：" &&
                              row.three != "回执单："
                                ? _c("div", [_vm._v(_vm._s(row.four))])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2370852642
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }