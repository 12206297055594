<template>
  <div class="declaration-detail-index">
    <div class="basic-detail-page" v-if="ifShowBasic">
      <div class="basic-detail-title">
        {{ tdOccupationalHazardDeclarationExp.unitName }}
      </div>
      <hr class="hr-dashed" />
      <el-row class="top-bottom-pad20">
        <el-col :span="8">
          <el-descriptions class="basic-descriptions" :column="1">
            <el-descriptions-item label="社会信用代码">{{ tdOccupationalHazardDeclarationExp.creditCode }}</el-descriptions-item>
            <el-descriptions-item label="经济类型">{{ tdOccupationalHazardDeclarationExp.economicName }}</el-descriptions-item>
            <el-descriptions-item label="企业规模">{{ enterpriseScaleDict[tdOccupationalHazardDeclarationExp.enterpriseScale] }}</el-descriptions-item>
            <el-descriptions-item label="行业分类">{{ tdOccupationalHazardDeclarationExp.industryName }}</el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="8">
          <el-descriptions class="basic-descriptions" :column="1">
            <el-descriptions-item label="注册地区">{{ tdOccupationalHazardDeclarationExp.registerName }}</el-descriptions-item>
            <el-descriptions-item label="注册地址">{{ tdOccupationalHazardDeclarationExp.registerAddr }}</el-descriptions-item>
            <el-descriptions-item label="经营地区">{{ tdOccupationalHazardDeclarationExp.bizName }}</el-descriptions-item>
            <el-descriptions-item label="经营地址">{{ tdOccupationalHazardDeclarationExp.workplaceAddr }}</el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="8" class="last-basic-descriptions">
          <el-descriptions :column="1">
            <el-descriptions-item label="法定代表人">{{ tdOccupationalHazardDeclarationExp.legalPerson }}</el-descriptions-item>
            <el-descriptions-item label="法定代表人电话">{{ tdOccupationalHazardDeclarationExp.legalPersonPhone }}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{ tdOccupationalHazardDeclarationExp.linkManager }}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{ tdOccupationalHazardDeclarationExp.linkManagerContact }}</el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
    </div>
    <base-headline title="人员情况" />
    <div class="basic-detail-page top-bottom-pad10">
      <div class="flex-div">
        <div>
          <span class="detail-label">在册职工总数：</span>
          <span class="font-size18-weight400 color-0037c7">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.totalStaffNum, '人') }}</span>
        </div>
        <div class="right-line-22"></div>
        <div>
          <span class="detail-label">外委人员总数：</span>
          <span class="font-size18-weight400 color-0037c7">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.totalSubcontractingStaff, '人') }}</span>
        </div>
        <div class="right-line-22"></div>
        <div>
          <span class="detail-label">接害总人数 (含外委)：</span>
          <span class="font-size18-weight400 color-fa940f">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.totalVictimsNum, '人') }}</span>
        </div>
        <div class="right-line-22"></div>
        <div>
          <span class="detail-label">职业病累计人数：</span>
          <span class="font-size18-weight400 color-ed0e25">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.cumulativePatients, '人') }}</span>
        </div>
        <div class="right-line-22"></div>
        <div>
          <span class="detail-label">在册接害人数：</span>
          <span class="font-size18-weight400 color-ed0e25">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.workerNum, '人') }}</span>
        </div>
      </div>
    </div>
    <base-headline title="职业卫生培训情况" />
    <div class="basic-detail-page top-bottom-pad10">
      <div class="flex-div">
        <div>
          <span class="detail-label">主要负责人培训：</span>
          <span :class="tdOccupationalHazardDeclarationExp.principalTraining ? 'color-159487' : 'color-dd3838'">{{ tdOccupationalHazardDeclarationExp.principalTraining ? '已培训' : '未培训' }}</span>
        </div>
        <div class="right-line-22" style="padding: 0 17px"></div>
        <div>
          <span class="detail-label">职业卫生管理人员培训：</span>
          <span :class="tdOccupationalHazardDeclarationExp.managerTraining ? 'color-159487' : 'color-dd3838'">{{ tdOccupationalHazardDeclarationExp.managerTraining ? '已培训' : '未培训' }}</span>
        </div>
        <div class="right-line-22" style="padding: 0 17px"></div>
        <div>
          <span class="detail-label">危害因素年度培训总人数：</span>
          <span class="detail-value">{{ formatNullDataByNoTable(tdOccupationalHazardDeclarationExp.totalTrainee, '人') }}</span>
        </div>
      </div>
      <div class="flex-div">
        <div>
          <span class="detail-label">主要负责人培训证明：</span>
          <span v-if="tdOccupationalHazardDeclarationExp.principalTraining && $zwxBase.verifyIsNotBlank(tdOccupationalHazardDeclarationExp.principalTrainingPath)">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview([{ fileName: '主要负责人培训证明', filePath: tdOccupationalHazardDeclarationExp.principalTrainingPath }], 0)">查看</el-button>
          </span>
          <span v-else>——</span>
        </div>
        <div class="right-line-22"></div>
        <div>
          <span class="detail-label">职业卫生管理人员培训证明：</span>
          <span v-if="tdOccupationalHazardDeclarationExp.managerTraining && $zwxBase.verifyIsNotBlank(tdOccupationalHazardDeclarationExp.managerTrainingPath)">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview(tdOccupationalHazardDeclarationExp.managerTrainingPath, 0)">查看</el-button>
          </span>
        </div>
        <div class="right-line-22"></div>
      </div>
    </div>
    <base-headline title="主要产品" class="bdBottom-no" />
    <div class="detail-card-0">
      <el-card class="zwx-card card-text-item-height35 card-text-item">
        <el-col :span="24">
          <el-table class="zwx-table" row-key="rid+'MajorProduct'" :data="tdOccupationalHazardDeclarationExp.tdMajorProductExpList" border stripe>
            <el-table-column prop="productName" label="产品名称" width="400" header-align="center" align="left"></el-table-column>
            <el-table-column prop="dosage" label="年产量" width="200" show-overflow-tooltip header-align="center" align="center"></el-table-column>
            <el-table-column prop="unit" label="计量单位" width="150" header-align="center" align="center"></el-table-column>
            <el-table-column label="" header-align="left" align="left"></el-table-column>
          </el-table>
        </el-col>
      </el-card>
    </div>
    <base-headline title="危害因素情况" class="bdTop-no">
      <div class="no-health" v-if="null == tdOccupationalHazardDeclarationExp.tdHealthSupervisionExp || !tdOccupationalHazardDeclarationExp.tdHealthSupervisionExp.existsSupervision"><span class="title">未开展体检</span></div>
    </base-headline>
    <div v-for="(declarationHazardMainPojo, index) in tdOccupationalHazardDeclarationExp.declarationHazardMainPojoList" :key="index">
      <div class="basic-detail-page top-bottom-pad10">
        <div class="flex-div">
          <div :class="'hazards-sort-title color-' + index">{{ hazardsSortDict[declarationHazardMainPojo.hazardsSort] }}</div>
          <div class="width-160">
            <span class="detail-label">总接触人数：</span>
            <span class="detail-value">{{ formatNullDataByNoTable(declarationHazardMainPojo.totalContacts, '人') }}</span>
          </div>
          <div class="right-line-22"></div>
          <div class="width-160">
            <span class="detail-label">总体检人数：</span>
            <span class="detail-value">{{ formatNullDataByNoTable(declarationHazardMainPojo.totalHealth, '人') }}</span>
          </div>
          <div class="right-line-22"></div>
          <div class="width-160">
            <span class="detail-label">总检测点数：</span>
            <span class="detail-value">{{ formatNullDataByNoTable(declarationHazardMainPojo.totalPoints, '') }}</span>
          </div>
          <div class="right-line-22"></div>
          <div class="width-160">
            <span class="detail-label">总超标点数：</span>
            <span class="detail-value">{{ formatNullDataByNoTable(declarationHazardMainPojo.totalOverproofPoints, '') }}</span>
          </div>
        </div>
      </div>
      <div class="detail-card-0">
        <el-card class="zwx-card card-text-item-height35 card-text-item">
          <el-col :span="24">
            <el-table class="zwx-table" row-key="hazardsSortCode+'declarationHazardSub'" :data="declarationHazardMainPojo.declarationHazardSubPojoList" border stripe>
              <el-table-column prop="hazardsSortName" label="接触危害因素" width="400" header-align="center" align="left"></el-table-column>
              <el-table-column prop="contactsNum" label="接触人数" width="200" :formatter="formatNullDataByTable" show-overflow-tooltip header-align="center" align="center"></el-table-column>
              <el-table-column prop="healthNum" label="体检人数" width="150" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
              <el-table-column prop="pointsNum" label="检测点数" width="150" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
              <el-table-column prop="overproofPointsNum" label="超标点数" width="150" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
              <el-table-column label="" header-align="left" align="left"></el-table-column>
            </el-table>
          </el-col>
        </el-card>
      </div>
    </div>
    <base-headline title="职业健康监护记录" class="bdBottom-no bdTop-no" />
    <div class="detail-card-0">
      <el-card class="zwx-card card-text-item-height35 card-text-item">
        <el-col :span="24">
          <el-table class="zwx-table" row-key="creditCode+'HealthSupervisionRecord'" :data="tdOccupationalHazardDeclarationExp.tdHealthSupervisionRecordExpList" border stripe>
            <el-table-column prop="unitName" label="检查机构名称" width="400" header-align="center" align="left"></el-table-column>
            <el-table-column prop="reportNo" label="检查报告编号" width="200" :formatter="formatNullDataByTable" show-overflow-tooltip header-align="center" align="center"></el-table-column>
            <el-table-column prop="creditCode" label="社会信用代码" width="300" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
            <el-table-column prop="contactInfo" label="联系电话" width="150" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
            <el-table-column label="" header-align="left" align="left"></el-table-column>
          </el-table>
        </el-col>
      </el-card>
      <base-headline title="场所检测评价记录" class="bdBottom-no bdTop-no" />
      <div class="detail-card-0">
        <el-card class="zwx-card card-text-item-height35 card-text-item">
          <el-col :span="24">
            <el-table class="zwx-table" row-key="creditCode+'HazardDetectionRecord'" :data="tdOccupationalHazardDeclarationExp.tdHazardDetectionRecordExpList" border stripe>
              <el-table-column prop="unitName" label="检测机构名称" width="400" header-align="center" align="left"></el-table-column>
              <el-table-column prop="reportNo" label="检测报告编号" width="200" :formatter="formatNullDataByTable" show-overflow-tooltip header-align="center" align="center">
                <template slot-scope="{ row }">
                  <a v-if="row.detectionReportUid" @click.prevent="previewFile(row.detectionReportUid)" class="td-text">
                    {{ row.reportNo }}
                  </a>
                  <span v-else>{{ row.reportNo }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="creditCode" label="社会信用代码" width="300" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
              <el-table-column prop="contactInfo" label="联系电话" width="150" :formatter="formatNullDataByTable" header-align="center" align="center"></el-table-column>
              <el-table-column label="" header-align="left" align="left"></el-table-column>
            </el-table>
          </el-col>
        </el-card>
      </div>
    </div>
    <base-headline title="申报信息" class="bdTop-no" />
    <el-table class="zwx-table" row-key="creditCode+'HazardDetectionRecord'" :data="list" :show-header="false" border stripe>
      <el-table-column prop="five" label="申报年份：" width="120" header-align="center" align="right"></el-table-column>
      <el-table-column prop="six" label="" width="180" header-align="center" align="left"></el-table-column>
      <el-table-column prop="one" label="申报日期：" width="120" header-align="center" align="right"></el-table-column>
      <el-table-column prop="two" label="" width="180" header-align="center" align="left"></el-table-column>
      <el-table-column prop="three" label="填报人：" width="100" header-align="center" align="right"></el-table-column>
      <el-table-column prop="four" label="" header-align="center" align="left"></el-table-column>
    </el-table>
    <base-headline class="bdTop-no" title="申报附件" v-if="tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList && tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList.length != 0" />
    <div class="attachments" v-if="tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList && tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList.length != 0">
      <span v-for="(annex, index) in tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList" :key="annex.rid + 'Annex'" @click="openFilePreview(tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList, index)">
        <!-- <img v-if="annex.fileSuffix.indexOf('pdf') != -1" src="@/assets/ico/file/icon_pdf.svg" alt="" /> -->
        <!-- <img v-else src="@/assets/ico/file/icon_jpg.svg" alt="" /> -->
        <i :class="resolvePre(annex)" style="color:#316CF5!important"></i>
        {{ annex.fileName }}
      </span>
    </div>
    <div v-if="(tdOccupationalHazardDeclarationExp.declareStatus === 2 || tdOccupationalHazardDeclarationExp.declareStatus === 3) && $zwxBase.verifyIsNotBlank(ohdApprovalRecord.approveResult)">
      <base-headline title="审核信息" :class="{ 'bdTop-no': !(tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList && tdOccupationalHazardDeclarationExp.tdHazardDeclarationAnnexExpList.length != 0) }" />
      <el-table class="zwx-table" row-key="creditCode+'HazardDetectionRecord'" :data="infoList" :span-method="arraySpanMethod" :show-header="false" border stripe>
        <el-table-column prop="one" label="审核状态" width="120" header-align="center" align="right"></el-table-column>
        <el-table-column prop="two" label="审核状态" width="180" :formatter="formatNullDataByTable" header-align="center" align="left">
          <template slot-scope="{ row }">
            <div v-if="row.one == '审核状态：'">
              <span :style="{ color: ohdApprovalRecord.approveResult == 1 ? '#00B652' : 'red' }">
                <i v-if="ohdApprovalRecord.approveResult == 1" class="el-icon-success"></i>
                <i v-else class="el-icon-error"></i>
                {{ row.two }}
              </span>
            </div>
            <div v-else>{{ row.two }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="three" label="" width="100" header-align="center" align="right"></el-table-column>
        <el-table-column prop="four" label="" :formatter="formatNullDataByTable" header-align="center" align="left">
          <template slot-scope="{ row }">
            <div v-if="row.three == '审核附件：'">
              <span v-if="$zwxBase.verifyIsNotBlank(row.four)">
                <el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview([{ fileName: '审核附件', filePath: row.four }], 0)">查看</el-button>
              </span>
              <span v-if="$zwxBase.verifyIsBlank(row.four)">-</span>
            </div>
            <div v-if="row.three == '回执单：'">
              <span v-if="$zwxBase.verifyIsNotBlank(tdOccupationalHazardDeclarationExp.receiptPath)">
                <el-button class="zwx-button zwx-button-text-26" type="text" @click="openFilePreview([{ fileName: tdOccupationalHazardDeclarationExp.unitName + '-回执单(' + tdOccupationalHazardDeclarationExp.receiptNo + ')', filePath: tdOccupationalHazardDeclarationExp.receiptPath }], 0)">查看</el-button>
              </span>
              <span v-if="$zwxBase.verifyIsBlank(tdOccupationalHazardDeclarationExp.receiptPath)">-</span>
            </div>
            <div v-if="row.three != '审核附件：' && row.three != '回执单：'">{{ row.four }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeclarationInfoDetail',
  props: {
    tdOccupationalHazardDeclarationExp: { type: Object, default: () => {} },
    ifShowBasic: { type: Boolean, default: true },
  },
  watch: {
    tdOccupationalHazardDeclarationExp(newVal, oldVal) {
      if (newVal) {
        this.list[0].two = this.$system.formatDate(this.tdOccupationalHazardDeclarationExp.declareDate, 'YYYY-MM-DD')
        if (this.$zwxBase.verifyIsBlank(this.tdOccupationalHazardDeclarationExp.informant)) {
          this.list[0].four = ''
        } else {
          if (this.$zwxBase.verifyIsBlank(this.tdOccupationalHazardDeclarationExp.informantContact)) {
            this.list[0].four = this.tdOccupationalHazardDeclarationExp.informant
          } else {
            this.list[0].four = this.tdOccupationalHazardDeclarationExp.informant + '（' + this.tdOccupationalHazardDeclarationExp.informantContact + '）'
          }
        }
        this.list[0].six = this.tdOccupationalHazardDeclarationExp.declareYear
        this.getOhdApprovalRecord()
      }
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      enterpriseScaleDict: ['', '大', '中', '小', '微'],
      hazardsSortDict: ['', '粉尘因素', '化学因素', '物理因素', '放射因素', '生物和其他因素'],
      list: [{ one: '申报日期：', two: '', three: '填报人：', four: '', five: '申报年份：', sex: '' }],
      ohdApprovalRecord: {},
      infoList: [
        { one: '审核状态：', two: '', three: '回执单：', four: '' },
        { one: '审核时间：', two: '', three: '审核附件：', four: '' },
        { one: '审核人：', two: '', three: '审核机构：', four: '' },
        { one: '审核备注：', two: '', three: '', four: '' },
      ],
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 预览检测附件
    previewFile(detectionReportUid) {
      this.$emit('loading', true)
      let data = {
        mainUid: detectionReportUid,
      }
      this.$system.postJson(
        this.api + '/oh/workplace/detection/getReportAnnexType1ListByMainUid-1',
        data,
        true,
        true,
        data => {
          this.$emit('', false)
          if (data.type === '00') {
            this.openFilePreview(data.reportAnnexExpList, 0)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 解析后缀名
     */
    resolvePre(item) {
      let prefix = item.fileSuffix
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      } else if (prefix === '.xlsx' || prefix === '.xls') {
        fileIconShow = 'colorful chis-icon-colorful-excel'
      }
      return fileIconShow
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 3) {
        // 合并第二行
        if (columnIndex === 1) {
          return [1, 3]
        } else if (columnIndex === 2 || columnIndex === 3) {
          return [0, 0]
        }
      }
    },
    getOhdApprovalRecord() {
      this.$emit('loading', true)
      let data = {
        fkByMainId: {
          rid: this.tdOccupationalHazardDeclarationExp.rid,
        },
      }
      this.$system.postJson(this.api + '/oh/declaration/getOhdApprovalRecordListByMainId-1', data, true, true, this.getOhdApprovalRecordSuccess, this.error)
    },
    getOhdApprovalRecordSuccess(data) {
      if (data.type === '00') {
        this.ohdApprovalRecord = data.ohdApprovalRecordList[0] || {}
        this.infoList[0].two = this.ohdApprovalRecord.approveResult == 1 ? '审核通过' : '审核退回'
        this.infoList[0].four = ''
        this.infoList[1].two = this.$system.formatDate(this.ohdApprovalRecord.approveDate, 'YYYY-MM-DD')
        this.infoList[1].four = this.ohdApprovalRecord.approvePath
        this.infoList[2].two = this.ohdApprovalRecord.approveUser
        this.infoList[2].four = this.ohdApprovalRecord.approveUnitName
        this.infoList[3].two = this.ohdApprovalRecord.approveRemark
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    openFilePreview(previewFileList, index) {
      let fileList = previewFileList
      if (!Array.isArray(fileList)) {
        fileList = fileList.split(',').map(item => {
          return {
            fileName: this.getFilenameFromPath(item),
            filePath: item,
          }
        })
      }
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList,
            index: index,
          },
        },
        '*'
      )
    },
    getFilenameFromPath(path) {
      return path
        .split('/')
        .pop()
        .split('\\')
        .pop()
    },
    /**
     * 表格数据中为空返回
     */
    formatNullDataByTable(row, column, cellValue) {
      if (this.$zwxBase.verifyIsBlank(cellValue)) {
        return '--'
      } else {
        return cellValue
      }
    },
    /**
     * 非表格数据中为空返回
     */
    formatNullDataByNoTable(cellValue, suffixName) {
      if (this.$zwxBase.verifyIsBlank(cellValue)) {
        return '--'
      } else {
        return cellValue + suffixName
      }
    },
  },
}
</script>

<style lang="less" scoped>
a[class='td-text'] {
  color: #0045f9 !important;
  cursor: pointer;
}
.no-health {
  margin-left: 15px;
  width: 68px;
  height: 18px;
  background: #ffaf01;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    width: 62px;
    font-size: 12px;
    font-family: Harmony Regular;
    font-weight: 400;
    color: #ffffff;
  }
}
.hazards-sort-title {
  min-width: 60px;
  padding-right: 20px;
}
.width-160 {
  width: 160px;
}
.bdTop-no.zwx-headline {
  /deep/ .zwx-head-title {
    border-top: none !important;
  }
}
.bdBottom-no {
  /deep/ .zwx-head-title {
    border-bottom: none !important;
  }
}
.attachments {
  display: flex;
  flex-flow: column;
  padding: 10px 30px;
  span {
    color: #2a50d7;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.declaration-detail-index {
  .audit-result div {
    margin-right: 25px;
  }
}
</style>

<style lang="less"></style>
