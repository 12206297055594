<template>
  <div class="main-page">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row ">
        <el-form-item label="课程名称：" label-width="70px">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.courseName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="包含考试：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifExamArr">
            <el-checkbox class="zwx-checkbox" :label="1">是</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">否</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态：" label-width="42px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableArr">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 16px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="query()">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addTrainingCourse">添加</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid+'-only'" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="courseName" label="课程名称" width="400" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="existExam" label="包含考试" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.existExam == true ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-normal">启用</div>
          </template>
          <template v-if="!scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-finish">停用</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 3, arrowNum: 1, btnNum: 3 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="editTrainingCourse(scope.row)" icon="el-icon-arrow-right">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === false" @click="enableOrDisable(scope.row)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === true" @click="enableOrDisable(scope.row)" style="color:rgb(231, 43, 0) !important;">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" v-if="scope.row.ifEnable === false" type="text" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteTrainingCourse)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
  </div>
</template>

<script>
export default {
  name: 'CourseManage',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        courseName: '',
        ifExamArr: [],
        ifEnableArr: [1],
      },
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
    }
  },
  created() { },
  mounted() {
    this.query()
  },
  activated() {
    if (this.$route.params.fresh) this.currentChange(this.currentPage)
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 查询
     */
    query() {
      this.currentPage = 1
      this.currentChange(1)
    },
    /**
     * 分页查询
     */
    currentChange(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        courseName: this.msForm.courseName,
        ifExamArr: this.msForm.ifExamArr,
        ifEnableArr: this.msForm.ifEnableArr,
        currentPage: currentPage,
      }
      this.$system.postJson(
        this.api + '/training/platform/getAllTrainingCourseList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.dataList = data.trainingCourseList
            this.total = data.trainingCourseCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 启用/停用
     */
    enableOrDisable(row) {
      var mess = row.ifEnable ? '停用' : '启用'
      this.$system.msgbox('', '提示', '是否确定' + mess + '？', '确定', '取消', () => {
        let data = {
          uuid: row.uuid,
          ifEnable: row.ifEnable ? false : true,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/training/platform/enableOrDisableTrainingCourseByCourseUuid-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type == '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.currentChange(this.currentPage)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },

    /**
     * 删除
     */
    deleteTrainingCourse(row) {
      let data = {
        uuid: row.uuid,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/deleteTrainingCourse-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.query()
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //添加
    addTrainingCourse(row) {
      this.$router.push({ name: 'CourseManageEdit', params: { type: 'add' } })
    },
    //编辑
    editTrainingCourse(row) {
      this.$router.push({ name: 'CourseManageEdit', params: { rid: row.uuid, type: 'edit' } })
    },
  },
}
</script>
<style lang="less" scoped></style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 300px;
}
</style>
