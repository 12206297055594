<template>
  <div class="hazard-declaration-view">
    <declaration-info-detail :tdOccupationalHazardDeclarationExp="techServiceBasic" :ifShowBasic="true"/>
  </div>
</template>

<script>
import DeclarationInfoDetail from './components/DeclarationInfoDetail'
export default {
  name: 'HazardDeclarationView',
  components: {
    DeclarationInfoDetail,
  },
  data() {
    return {
      api: this.$store.state.api,
      rid: this.$route.params.rid,
      businessUuid: this.$route.params.businessUuid,
      techServiceBasic: {},
    }
  },
  created() { },
  mounted() {
    this.detail()
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  methods: {
    detail() {
      this.$emit('loading', true)
      let data = {
        rid: this.rid,
        uuid:this.businessUuid
      }
      this.$system.get(this.api + '/oh/declaration/getOccupationalHazardDeclaration-1', data, true, true, this.detailSuccess, this.error)
    },
    detailSuccess(data) {
      if (data.type === '00') {
        this.techServiceBasic = Object.assign({}, data.resultData)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped>

.hazard-declaration-view {
  // 人员情况
  .employer-person-situation {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 16px;

    .employer-person-situation-label {
      font-size: 14px;
      text-align: left;
      color: #3a485e;
      line-height: 14px;
    }

    .staff-num {
      font-size: 18px;
      text-align: left;
      color: #0037c7;
      line-height: 18px;
    }

    .employer-basic-text-line {
      width: 1px;
      height: 16px;
      background: #dfe2e8;
      margin: 0 12px;
    }


    .basic-information-text-outsourcing {
      color: rgba(0, 55, 199, 1);
      font-size: 18px;
      line-height: 18px;
    }
    .basic-information-text-damage {
      color: rgba(250, 148, 15, 1);
      font-size: 18px;
      line-height: 18px;
    }
    .basic-information-text-cumulative {
      color: rgba(237, 14, 37, 1);
      font-size: 18px;
      line-height: 18px;
    }
    .basic-information-text-table {
      font-size: 14px;
      font-weight: 400;
      color: rgba(58, 72, 94, 1);
      line-height: 14px;
      border-top: 1px solid rgba(213, 217, 224, 1);
      border-collapse: collapse;
      margin-top: 18px;
      th,
      td {
        border: 1px solid rgba(213, 217, 224, 1);
        border-top: none;
      }
      .basic-information-text-table-key {
        min-width: 120px;
        padding: 12px 0;
        text-align: right;
      }
      .basic-information-text-table-value {
        color: rgba(25, 25, 25, 1);
        min-width: 280px;
        padding: 12px;
      }
    }
  }
  // 职业卫生培训情况
  .occupational-health-train {
    color: rgba(58, 72, 94, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 18px 20px;
    .occupational-health-train-notrain {
      color: rgba(221, 56, 56, 1);
      margin-right: 30px;
    }
    .occupational-health-train-trained {
      color: rgba(25, 25, 25, 1);
      margin-right: 30px;
    }
    .occupational-health-train-hazard {
      color: rgba(25, 25, 25, 1);
    }
  }

  .base-flag {
    display:block;
    text-align: center;
    height: 18px;
    background: #FFAF01 !important;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .base-headline-slot {
    margin-left: 20px;
    padding: 3px 4px;
    background: rgba(255, 175, 1, 1);
    border-radius: 2px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }

  // 危害因素情况
  .hazard-factors {
    color: rgba(58, 72, 94, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 18px 20px 0 20px;
    .hazard-factors-brown {
      color: rgba(156, 109, 30, 1);
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
    .hazard-factors-green {
      color: rgba(21, 148, 135, 1);
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
    .hazard-factors-pink {
      color: rgba(163, 45, 173, 1);
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
    .hazard-factors-line {
      height: 12px;
      border-left: 1px solid rgba(223, 226, 232, 1);
      margin: 0 12px;
    }
  }

  /deep/ .supervision-record-table th {
    border-top: unset !important;
  }
  // 申报信息
  .declaration-information {
    padding: 0 20px 14px 20px;
    .declaration-information-table {
      margin-top: 18px;
      border: 1px solid #D5D9E0;

      .declaration-information-table-row {
        display: flex;
        width: 100%;

        .declaration-information-table-label {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          min-width: 122px;
          height: 38px;
          font-size: 14px;
          color: #3a485e;
          line-height: 14px;
          border-right: 1px solid #D5D9E0;
        }

        .declaration-information-table-value {
          display: flex;
          align-items: center;
          min-width: 280px;
          height: 38px;
          font-size: 14px;
          color: #191919;
          line-height: 14px;
          padding-left: 5px;
          border-right: 1px solid #D5D9E0;
        }

        .declaration-information-table-value:last-child{
          border-right: unset;
        }
      }
    }
  }
}
</style>

<style lang="less"></style>
