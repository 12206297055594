import { render, staticRenderFns } from "./CourseManage.vue?vue&type=template&id=0eb69da5&scoped=true"
import script from "./CourseManage.vue?vue&type=script&lang=js"
export * from "./CourseManage.vue?vue&type=script&lang=js"
import style1 from "./CourseManage.vue?vue&type=style&index=1&id=0eb69da5&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb69da5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\training-vue\\ms-vue-training-platform\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0eb69da5')) {
      api.createRecord('0eb69da5', component.options)
    } else {
      api.reload('0eb69da5', component.options)
    }
    module.hot.accept("./CourseManage.vue?vue&type=template&id=0eb69da5&scoped=true", function () {
      api.rerender('0eb69da5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/courseManage/CourseManage.vue"
export default component.exports