var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hazard-declaration-view" },
    [
      _c("declaration-info-detail", {
        attrs: {
          tdOccupationalHazardDeclarationExp: _vm.techServiceBasic,
          ifShowBasic: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }